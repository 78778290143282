// productSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
  loading: false,
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    fetchProducts: (state, action) => {
      state.products = action.payload;
      state.loading = false;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    updateProduct: (state, action) => {
      const updatedProduct = action.payload;
      const productIndex = state.products.findIndex(
        (product) => product.id === updatedProduct.id
      );
      if (productIndex !== -1) {
        state.products[productIndex] = updatedProduct;
      }
    },
  },
});

export const { fetchProducts, setLoading, updateProduct } =
  productSlice.actions;

export default productSlice.reducer;
