export const orderDetailsColumns = [
  {
    accessorKey: "product.name",
    header: "Product Name",
  },
  {
    accessorKey: "variant.variantId.name",
    header: "Variant",
  },
  {
    accessorKey: "quantity",
    header: "Quantity",
  },
  {
    accessorKey: "product.price",
    header: "Price",
    Cell: ({ cell }) => <span>${cell.getValue()}</span>,
  },
  {
    accessorKey: "total",
    header: "total",
    Cell: ({ row }) => <span>${calculateTotal(row)}</span>,
  },
];

const calculateTotal = (row) => {
  const quantity = row.original.quantity;
  const price = row.original.product.price;
  return quantity * price;
};
export const orderDetails = [
  {
    product_name: "MacBook Pro",
    price: 892.23,
    quantity: 4,
    total: 2492.54,
  },
  {
    product_name: "Apple watch",
    price: 643.23,
    quantity: 4,
    total: 1246.54,
  },
];
