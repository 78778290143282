import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../api/api";
import Table from "../components/Table";
import { ordersColumns } from "../data/orders";
import { setLoading, setOrderDetails } from "../store/orderSlice";
import { joinObjectPropertiesWithComma } from "../utilities/common";

const SingleProduct = () => {
  const { id } = useParams();
  const customers = useSelector((state) => state.customer.customers);
  const orderDetails = useSelector((state) => state.order.orderDetails); // Access orderDetails from the Redux store
  const loading = useSelector((state) => state.order.loading); // Access loading state from the Redux store

  const customer = customers.find((customer) => customer._id === id);

  const { name, email, shippingAddress, phone, img } = customer;

  const fullAddress = joinObjectPropertiesWithComma(shippingAddress, [
    "address1",
    "city",
    "postalCode",
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoading(true)); // Set loading state to true before fetching data
    api
      .get("/orders")
      .then((response) => {
        dispatch(setOrderDetails(response.data.payload)); // Update orderDetails in the store
        dispatch(setLoading(false)); // Set loading state to false after fetching data
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
        dispatch(setLoading(false)); // Set loading state to false in case of an error
      });
  }, [dispatch]);
  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Typography variant="h5">Customer Details</Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4} sx={{ mt: 4 }}>
          <Paper
            sx={{
              boxShadow: "none !important",
              borderRadius: "12px",
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "divider",
              p: "20px",
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Avatar
                src={img}
                sx={{ width: "80px", height: "80px", mx: "auto" }}
              />
              <Typography variant="h6" sx={{ my: 1 }}>
                {name}
              </Typography>

              <Box sx={{ mt: 2 }}>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: "15px", mb: 2 }}
                >
                  Email: <span style={{ opacity: 0.7 }}>{email}</span>
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: "15px", mb: 2 }}
                >
                  Phone: <span style={{ opacity: 0.7 }}>{phone}</span>
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: "15px", mb: 2 }}
                >
                  Address: <span style={{ opacity: 0.7 }}>{fullAddress}</span>
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <Typography variant="h6">Order List</Typography>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Table
              data={orderDetails?.filter(
                (order) => order?.customer?._id === id
              )}
              fields={ordersColumns.filter(
                (col) => col.accessorKey !== "customer"
              )}
              numberOfRows={
                orderDetails?.filter((order) => order?.customer?._id === id)
                  ?.length
              }
              enableTopToolBar={false}
              enableBottomToolBar={false}
              enablePagination={false}
              enableRowSelection={false}
              enableColumnFilters={false}
              enableEditing={false}
              enableColumnDragging={false}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SingleProduct;
