export const customersColumns = [
  // {
  //   accessorKey: "img", //access nested data with dot notation
  //   header: "Image",
  //   size: 100,
  //   Cell: ({ cell }) => (
  //     <div>
  //       <Avatar src={cell.getValue()} sx={{ width: 30, height: 30 }} />
  //     </div>
  //   ),
  // },
  {
    // accessorKey: "name", //access nested data with dot notation
    header: "Customer Name",
    Cell: ({ row }) => `${row.original.firstName} ${row.original.lastName}`,
  },
  {
    accessorKey: "email",
    header: "Email",
  },
  {
    accessorKey: "shippingAddress", //normal accessorKey
    header: "Address",
    Cell: ({ cell }) => (
      <div>
        {cell.getValue() &&
          `${cell.getValue().address1},${cell.getValue().city},${
            cell.getValue().postalCode
          }`}
      </div>
    ),
  },
  {
    accessorKey: "phone",
    header: "Phone Number",
  },
];
