import moment from "moment";

export const ordersColumns = [
  {
    accessorKey: "_id",
    header: "Order ID",
  },
  {
    accessorKey: "customer",
    header: "Customer Email",
    Cell: ({ cell }) => `${cell.getValue()?.email}`,
  },
  {
    accessorKey: "items", //access nested data with dot notation
    header: "Products",
    Cell: ({ cell }) => {
      const items = cell.getValue() || [];
      const itemNames = items.map((item) => item?.product?.name).join(", ");
      return <span>{itemNames}</span>;
    },
  },
  {
    accessorKey: "createdAt",
    header: "Date",
    Cell: ({ cell }) => moment(cell.getValue()).format("DD MMM YYYY hh:mm a"),
  },
  {
    accessorKey: "status",
    header: "Status",
    Cell: ({ cell }) => {
      let statusStyle;
      switch (cell.getValue()) {
        case "Pending":
          statusStyle = { color: "gray" };
          break;
        case "Shipped":
          statusStyle = { color: "orange" };
          break;
        case "Delivered":
          statusStyle = { color: "green" };
          break;
        case "Cancelled":
          statusStyle = { color: "red" };
          break;
        default:
          statusStyle = {};
      }
      return <span style={statusStyle}>{cell.getValue()}</span>;
    },
  },
  {
    accessorKey: "total",
    header: "Amount",
    Cell: ({ cell }) => `$${cell.getValue()}`,
  },
];
