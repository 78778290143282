import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../api/api";
import MultiFileUpload from "../components/MultiFileUpload";
import { clearImages } from "../store/imageUploadSlice";

const AddProduct = () => {
  const [availableCategories, setAvailableCategories] = useState([]);
  const [variants, setVariants] = useState([]);
  const [variantPriceMapping, setVariantPriceMapping] = useState([]);
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [loading, setLoading] = useState(true);
  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [priceError, setPriceError] = useState("");
  const selectedImages = useSelector(
    (state) => state.imageUpload.selectedImages
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    loadVariants();
    api
      .get("/categories")
      .then((response) => {
        setAvailableCategories(response.data.payload);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
        setLoading(false); // Handle error by setting loading to false
      });
  }, []);

  const resetFormFields = () => {
    setName("");
    setDescription("");
    setCategory("");
    setPrice("");
    setNameError("");
    setDescriptionError("");
    setCategoryError("");
    setPriceError("");
    setVariantPriceMapping([]);
    dispatch(clearImages());
  };

  const handleSubmit = () => {
    setLoading(true);
    if (!name) {
      setNameError("Product Name is required.");
      return;
    }
    if (!description) {
      setDescriptionError("Product Description is required.");
      return;
    }
    if (!category) {
      setCategoryError("Please select a Category.");
      return;
    }
    if (!price) {
      setPriceError("Price is required.");
      return;
    }
    if (variantPriceMapping?.some((item) => !item.price)) {
      toast.error("Variant Price is required");
      return;
    }

    const productData = new FormData();
    productData.append("name", name);
    productData.append("description", description);
    productData.append("id_category", category);
    productData.append("price", price);
    selectedImages.forEach((image, index) => {
      productData.append(`images`, image.file);
    });

    productData.append("variants", JSON.stringify(variantPriceMapping));
    api
      .post("/products", productData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        resetFormFields();
        console.log("Product created successfully:", response.data);
        toast.success("Product created successfully!");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error creating product:", error);
        toast.error("Error creating product.");
        setLoading(false);
      });
  };

  const loadVariants = async () => {
    api
      .get("/variant")
      .then((response) => {
        setVariants(response.data);
        setVariantPriceMapping([
          { variantId: response?.data[0]?._id, price: 0 },
        ]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching variants:", error);
        setLoading(false); // Handle error by setting loading to false
      });
  };

  const handleVariantPriceChange = (
    variantId,
    price = 0,
    changedVariantId = null
  ) => {
    const previousState = [...variantPriceMapping];
    const index = previousState.findIndex(
      (item) => item.variantId === variantId
    );
    if (changedVariantId) {
      previousState[index].variantId = changedVariantId;
    } else if (index > -1 && !isNaN(+price)) {
      previousState[index].price = price;
    } else {
      previousState.push({ variantId, price });
    }
    setVariantPriceMapping(previousState);
  };

  const handleAdd = () => {
    if (variantPriceMapping?.length !== variants?.length) {
      const previousState = [...variantPriceMapping];
      const nonAddedVariants = variants?.filter(
        (item) => !variantPriceMapping?.some((el) => el.variantId === item?._id)
      );
      previousState.push({ variantId: nonAddedVariants[0]?._id, price: 0 });
      setVariantPriceMapping(previousState);
    }
  };

  const handleRemove = (index) => {
    if (variantPriceMapping?.length) {
      const previousState = [...variantPriceMapping];
      previousState.splice(index, 1);
      setVariantPriceMapping(previousState);
    }
  };
  return (
    <React.Fragment>
      <Box sx={{ pt: "80px", pb: "20px" }}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ pt: "80px", pb: "20px" }}>
            <Typography variant="h6" sx={{ marginBottom: "14px" }}>
              Add Product
            </Typography>
            <Paper
              sx={{
                boxShadow: "none !important",
                borderRadius: "12px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "divider",
                p: "20px",
                maxWidth: "800px",
                margin: "0 auto",
                cursor: "pointer",
                overflow: "hidden",
              }}
            >
              <Box sx={{ my: 2 }}>
                <TextField
                  label="Product Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameError("");
                  }}
                  error={!!nameError}
                  helperText={nameError}
                />
              </Box>
              <Box sx={{ mt: 4 }}>
                <TextField
                  label="Product Description"
                  variant="outlined"
                  rows={4}
                  fullWidth
                  multiline
                  required
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    setDescriptionError("");
                  }}
                  error={!!descriptionError}
                  helperText={descriptionError}
                />
              </Box>
              <Box sx={{ mt: 4 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Category"
                    value={category}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setCategory(e.target.value);
                      setCategoryError("");
                    }}
                    error={!!categoryError}
                    helperText={categoryError}
                    required
                  >
                    {availableCategories?.map(({ _id, name }) => (
                      <MenuItem value={_id} key={_id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box
                sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}
              >
                <TextField
                  label="Default Price"
                  variant="outlined"
                  rows={4}
                  type="number"
                  fullWidth
                  size="small"
                  required
                  value={price}
                  onChange={(e) => {
                    setPrice(e.target.value);
                    setPriceError("");
                  }}
                  error={!!priceError}
                  helperText={priceError}
                />
              </Box>
              <Box sx={{ mt: 4 }}>
                {variantPriceMapping?.map((item, index) => (
                  <Grid
                    container
                    spacing={3}
                    key={item?.variantId}
                    sx={{ mt: 2 }}
                  >
                    <Grid item xs={6}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="variant">Variant</InputLabel>
                        <Select
                          label="Variant"
                          value={item?.variantId}
                          onChange={(e) => {
                            handleVariantPriceChange(
                              item?.variantId,
                              0,
                              e.target?.value
                            );
                          }}
                          required
                        >
                          {variants?.map(({ _id, name }) => (
                            <MenuItem
                              value={_id}
                              key={_id}
                              disabled={variantPriceMapping.some(
                                (el) => el.variantId === _id
                              )}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        label="Price"
                        variant="outlined"
                        rows={4}
                        type="number"
                        fullWidth
                        size="small"
                        required
                        value={item?.price}
                        onChange={(e) => {
                          handleVariantPriceChange(
                            item?.variantId,
                            e.target.value
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          handleAdd();
                        }}
                      >
                        +
                      </Button>
                    </Grid>
                    {index !== 0 && (
                      <Grid item xs={2}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            handleRemove(index);
                          }}
                        >
                          -
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                ))}
              </Box>
              <MultiFileUpload />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: "30px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{ borderRadius: "20px" }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Box>
            </Paper>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

export default AddProduct;
