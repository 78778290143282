import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../api/api";
import AddVariant from "../components/AddVariant.jsx";
import UpdateVariant from "../components/EditVariant";
import Table from "../components/Table";
import { variantsColumns } from "../data/variants";
import { setVariants } from "../store/variantSlice";

const Variants = () => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);
  const variants = useSelector((state) => state.variant.variants);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const toggleModal = () => {
    setOpen(!open);
  };

  const handleEditRow = (row) => {
    setSelectedRow(row);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedRow(null);
  };
  const handleDeleteCategory = async (variant) => {
    api
      .delete(`/variant/${variant.original._id}`)
      .then((response) => {
        setLoading(false);
        loadVariant();
        toast.success("Variant deleting successfully!");
      })
      .catch((error) => {
        console.error("Error deleting variant:", error);
        setLoading(false);
        toast.error(
          error?.response.data.message
            ? error?.response.data.message
            : "Error deleting variant."
        );
      });
  };

  useEffect(() => {
    loadVariant();
  }, [dispatch]);

  const loadVariant = async () => {
    api
      .get("/variant")
      .then((response) => {
        dispatch(setVariants(response.data));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching variants:", error);
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">Variants</Typography>

          <Button
            variant="contained"
            color="primary"
            startIcon={<FiPlus />}
            sx={{ borderRadius: "20px" }}
            onClick={toggleModal}
          >
            Add Variant
          </Button>
        </Box>

        <AddVariant open={open} toggleModal={toggleModal} />

        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Table
            data={variants}
            fields={variantsColumns}
            numberOfRows={variants?.length}
            enableTopToolBar={true}
            enableBottomToolBar={true}
            enablePagination={true}
            enableRowSelection={true}
            enableColumnFilters={true}
            enableEditing={true}
            enableColumnDragging={true}
            routeLink="variants"
            isModalEdit={true}
            EditModalComponent={UpdateVariant}
            handleEditRow={handleEditRow}
            onDeleteRow={handleDeleteCategory}
          />
        )}
        {selectedRow && (
          <UpdateVariant
            open={openEditModal}
            handleClose={handleCloseEditModal}
            variant={selectedRow}
          />
        )}
      </Box>
    </React.Fragment>
  );
};

export default Variants;
