import { Alert, Snackbar } from "@mui/material";
import Box from "@mui/material/Box";
import React, { createContext, useEffect, useRef, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Navbar from "./components/common/Navbar";
import Sidebar from "./components/common/Sidebar";
import {
  AddProduct,
  Customers,
  EditProduct,
  Orders,
  ProductCategories,
  Products,
  SingleCustomer,
  SingleOrder,
  SingleProduct,
  Variants,
} from "./pages";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";

const sideBarWidth = 250;
export const ErrorContext = createContext();

function App() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isMountedRef = useRef(true);
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [error, setError] = useState(null);
  const handleLogin = (token = "") => {
    setIsLoggedIn(token ? true : false);
    localStorage.setItem("token", token); // Store login state in localStorage
  };

  const handleLogout = (mountedRef) => {
    if (mountedRef) {
      setIsLoggedIn(false);
      localStorage.removeItem("token"); // Remove login state from localStorage
    }
  };

  useEffect(() => {
    const storedLoginState = localStorage.getItem("token");
    if (storedLoginState) {
      setIsLoggedIn(true);
    } else {
      navigate("/login");
    }
    return () => {
      isMountedRef.current = false; // Update the mounted state when component unmounts
    };
  }, []);

  return (
    <ErrorContext.Provider value={{ error, setError, setOpenSnackbar }}>
      <Box sx={{ display: "flex" }}>
        {isLoggedIn && (
          <>
            <Navbar
              sideBarWidth={sideBarWidth}
              handleDrawerToggle={handleDrawerToggle}
              handleLogout={handleLogout}
              isMountedRef={isMountedRef}
            />
            <Sidebar
              sideBarWidth={sideBarWidth}
              mobileOpen={mobileOpen}
              handleDrawerToggle={handleDrawerToggle}
            />
          </>
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            px: { xs: 1, md: 2 },
            width: {
              xs: "100%",
              md: `calc(100% - ${isLoggedIn ? sideBarWidth : 0}px)`,
            },
          }}
        >
          {error && (
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert onClose={handleCloseSnackbar} severity="error">
                {error}
              </Alert>
            </Snackbar>
          )}
          <Routes>
            {!isLoggedIn ? (
              <Route
                path="/login"
                element={<Login handleLogin={handleLogin} />}
              />
            ) : (
              <>
                <Route path="/" element={<Dashboard />} />
                <Route path="/products" element={<Products />} />
                <Route path="/products/add" element={<AddProduct />} />
                <Route path="/products/edit/:id" element={<EditProduct />} />
                <Route path="/products/:id" element={<SingleProduct />} />
                <Route
                  path="/products/categories"
                  element={<ProductCategories />}
                />
                <Route path="/customers" element={<Customers />} />
                <Route path="/customers/:id" element={<SingleCustomer />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/orders/:id" element={<SingleOrder />} />
                <Route path="/variants" element={<Variants />} />
              </>
            )}
            {/* Add a default route or a fallback route here */}
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </Box>
      </Box>
    </ErrorContext.Provider>
  );
}

export default App;
