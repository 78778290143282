import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../api/api";
import { addVariant } from "../store/variantSlice";

const AddVariant = ({ open, toggleModal }) => {
  const [variant, setVariant] = useState("");
  const [variantErrors, setVariantErrors] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (!variant) {
      setVariantErrors("Variant is required.");
      return;
    }

    const productData = {
      name: variant,
    };

    api
      .post("/variant", productData)
      .then((response) => {
        console.log("Variant created successfully:", response.data);
        dispatch(addVariant(response.data));
        toggleModal();
        setVariant("");
        toast.success("Variant created successfully!");
      })
      .catch((error) => {
        console.error("Error creating variant:", error);
        toast.error(
          error?.response.data.message
            ? error?.response.data.message
            : "Error creating variant."
        );
      });
  };

  return (
    <React.Fragment>
      <div>
        <Dialog open={open} onClose={toggleModal}>
          <DialogTitle>Add Variant Please</DialogTitle>
          <DialogContent>
            <DialogContentText>Provide variant name</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="variant"
              label="Variant Name"
              type="text"
              fullWidth
              variant="standard"
              required
              value={variant}
              onChange={(e) => {
                setVariant(e.target.value);
                setVariantErrors("");
              }}
              error={!!variantErrors}
              helperText={variantErrors}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleModal}>Cancel</Button>
            <Button onClick={handleSubmit} variant="contained">
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default AddVariant;
