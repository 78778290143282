import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, CircularProgress } from "@mui/material";
import Table from "../components/Table";
import { customersColumns } from "../data/customers";
import api from "../api/api";
import { setCustomers, setLoading } from "../store/customerSlice"; // Import the actions

const Customers = () => {
  const customers = useSelector((state) => state.customer.customers);
  const loading = useSelector((state) => state.customer.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch customers from the server
    dispatch(setLoading(true)); // Set loading to true while fetching
    api
      .get("/customers")
      .then((response) => {
        dispatch(setCustomers(response.data.payload));
      })
      .catch((error) => console.error("Error fetching customers:", error))
      .finally(() => {
        dispatch(setLoading(false));
      });
  }, [dispatch]);

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      {/* Conditional rendering for loading indicator */}
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <React.Fragment>
          <Typography variant="h6" sx={{ marginBottom: "14px" }}>
            Customers
          </Typography>
          <Table
            data={customers}
            fields={customersColumns}
            numberOfRows={customers.length}
            enableTopToolBar={true}
            enableBottomToolBar={true}
            enablePagination={true}
            enableRowSelection={true}
            enableColumnFilters={true}
            enableEditing={true}
            enableColumnDragging={true}
            showPreview
            routeLink="customers"
          />
        </React.Fragment>
      )}
    </Box>
  );
};

export default Customers;
