import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../api/api";
import { updateVariant as updateVariantRedux } from "../store/variantSlice";

const UpdateVariant = ({ open, handleClose, variant }) => {
  const [updateVariant, setUpdateVariant] = useState(variant?.original?.name);
  const [variantErrors, setVariantErrors] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (variant.original.name === updateVariant) {
      setVariantErrors("Make changes to update.");
      return;
    }
    if (!updateVariant) {
      setVariantErrors("Variant is required.");
      return;
    }

    const updatedData = {
      name: updateVariant,
    };

    api
      .put(`/variant/${variant.original._id}`, updatedData)
      .then((response) => {
        console.log("Variant updated successfully:", response.data);
        dispatch(updateVariantRedux(response.data));
        handleClose();
        toast.success("Variant updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating Variant:", error);
        toast.error(error.response.data.message || "Error updating Variant.");
      });
  };

  return (
    <React.Fragment>
      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Edit Variant</DialogTitle>
          <DialogContent>
            <DialogContentText>Update variant name</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="VariantName"
              label="Variant Name"
              type="text"
              fullWidth
              variant="standard"
              required
              value={updateVariant}
              onChange={(e) => {
                setUpdateVariant(e.target.value);
                setVariantErrors("");
              }}
              error={!!variantErrors}
              helperText={variantErrors}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit} variant="contained">
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default UpdateVariant;
