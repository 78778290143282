// customerSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customers: [],
  loading: false, // Add loading state
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomers: (state, action) => {
      state.customers = action.payload;
      state.loading = false; // Set loading to false after data is fetched
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setCustomers, setLoading } = customerSlice.actions;
export default customerSlice.reducer;
