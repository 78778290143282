import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import api from "../api/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addCategory } from "../store/categorySlice";
import { useDispatch } from "react-redux";

const AddCategory = ({ open, toggleModal }) => {
  const [category, setCategory] = useState("");
  const [categoryErrors, setCategoryErrors] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (!category) {
      setCategoryErrors("Category is required.");
      return;
    }

    const productData = {
      name: category,
    };

    api
      .post("/categories", productData)
      .then((response) => {
        console.log("Category created successfully:", response.data);
        dispatch(addCategory(response.data.payload));
        toggleModal();
        setCategory("");
        toast.success("Category created successfully!");
      })
      .catch((error) => {
        console.error("Error creating category:", error);
        toast.error(
          error?.response.data.message
            ? error?.response.data.message
            : "Error creating category."
        );
      });
  };

  return (
    <React.Fragment>
      <div>
        <Dialog open={open} onClose={toggleModal}>
          <DialogTitle>Add Category Please</DialogTitle>
          <DialogContent>
            <DialogContentText>Provide category name</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="category"
              label="Category Name"
              type="text"
              fullWidth
              variant="standard"
              required
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
                setCategoryErrors("");
              }}
              error={!!categoryErrors}
              helperText={categoryErrors}
            />
            {/* <TextField
            autoFocus
            margin="dense"
            id="number"
            label="Number of Products"
            type="number"
            fullWidth
            variant="standard"
          /> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleModal}>Cancel</Button>
            <Button onClick={handleSubmit} variant="contained">
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default AddCategory;
