import { useState } from "react";
import "../styles/fileUpload.css";
import { customAlphabet } from "nanoid";
import { useDispatch, useSelector } from "react-redux";
import { addImage, clearImages, removeImage } from "../store/imageUploadSlice";

const MultiFileUpload = () => {
  const [Files, SetFiles] = useState([]);
  const selectedImages = useSelector(
    (state) => state.imageUpload.selectedImages
  );
  const dispatch = useDispatch();

  const fileSizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const InputChange = (e) => {
    let images = [];
    for (let i = 0; i < e.target.files.length; i++) {
      images.push(e.target.files[i]);
      let reader = new FileReader();
      let file = e.target.files[i];
      reader.onloadend = () => {
        dispatch(
          addImage([
            ...selectedImages,
            {
              id: customAlphabet("1234567890abcdef", 10),
              filename: e.target.files[i].name,
              filetype: e.target.files[i].type,
              fileImage: reader.result,
              file: e.target.files[i],
              dateTime:
                e.target.files[i].lastModifiedDate.toLocaleString("en-IN"),
              fileSize: fileSizes(e.target.files[i].size),
            },
          ])
        );
      };
      if (e.target.files[i]) {
        reader.readAsDataURL(file);
      }
    }
  };

  const DeleteSelectFile = (id) => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      dispatch(removeImage(id));
    }
  };

  const FileUploadSubmit = async (e) => {
    e.preventDefault();

    e.target.reset();
    if (selectedImages.length > 0) {
      for (let index = 0; index < selectedImages.length; index++) {
        SetFiles((preValue) => {
          return [...preValue, selectedImages[index]];
        });
      }
      dispatch(clearImages());
      SetFiles([]);
    } else {
      alert("Please select file");
    }
  };

  const DeleteFile = async (id) => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      SetFiles((preValue) => preValue.filter((data) => data.id !== id));
      dispatch(removeImage(id));
    }
  };

  return (
    <div className="card-body">
      <div className="kb-data-box">
        <div className="kb-modal-data-title">
          <div className="kb-data-title">
            <h6>Multiple File Upload With Preview</h6>
          </div>
        </div>
        <form onSubmit={FileUploadSubmit}>
          <div className="kb-file-upload">
            <div className="file-upload-box">
              <input
                type="file"
                id="fileUpload"
                accept="image/*"
                className="file-upload-input"
                onChange={InputChange}
                multiple
              />
              <span>
                Drag and drop or{" "}
                <span className="file-link">Choose your files</span>
              </span>
            </div>
          </div>
          <div className="kb-attach-box mb-3">
            {selectedImages.map((data, index) => {
              const { id, filename, filetype, fileImage, dateTime, fileSize } =
                data;
              return (
                <div className="file-atc-box" key={id}>
                  {filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ? (
                    <div className="file-image">
                      {" "}
                      <img src={fileImage} alt="" />
                    </div>
                  ) : (
                    <div className="file-image">
                      <i className="far fa-file-alt"></i>
                    </div>
                  )}
                  <div className="file-detail">
                    <h6>{filename}</h6>
                    <p></p>
                    <p>
                      <span>Size : {fileSize}</span>
                      <span className="ml-2">Modified Time : {dateTime}</span>
                    </p>
                    <div className="file-actions">
                      <button
                        type="button"
                        className="file-action-btn"
                        onClick={() => DeleteSelectFile(id)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </form>
        {Files.length > 0 ? (
          <div className="kb-attach-box">
            <hr />
            {Files.map((data, index) => {
              const { id, filename, filetype, fileImage, dateTime, fileSize } =
                data;
              return (
                <div className="file-atc-box" key={index}>
                  {filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ? (
                    <div className="file-image">
                      {" "}
                      <img src={fileImage} alt="" />
                    </div>
                  ) : (
                    <div className="file-image">
                      <i className="far fa-file-alt"></i>
                    </div>
                  )}
                  <div className="file-detail">
                    <h6>{filename}</h6>
                    <p>
                      <span>Size : {fileSize}</span>
                      <span className="ml-3">Modified Time : {dateTime}</span>
                    </p>
                    <div className="file-actions">
                      <button
                        className="file-action-btn"
                        onClick={() => DeleteFile(id)}
                      >
                        Delete
                      </button>
                      <a
                        href={fileImage}
                        className="file-action-btn"
                        download={filename}
                      >
                        Download
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default MultiFileUpload;
