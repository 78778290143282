import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedImages: [],
};

const imageUploadSlice = createSlice({
  name: "imageUpload",
  initialState,
  reducers: {
    addImage: (state, action) => {
      state.selectedImages = [...state.selectedImages, ...action.payload];
    },
    removeImage: (state, action) => {
      state.selectedImages = state.selectedImages.filter(
        (image) => image.id !== action.payload
      );
    },
    clearImages: (state) => {
      state.selectedImages = [];
    },
  },
});

export const { addImage, removeImage, clearImages } = imageUploadSlice.actions;

export default imageUploadSlice.reducer;
