import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../api/api";
import Table from "../components/Table";
import { productsColumns } from "../data/products";
import { fetchProducts, setLoading } from "../store/productSlice";

const Products = () => {
  const products = useSelector((state) => state.product.products);
  const loading = useSelector((state) => state.product.loading);
  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    dispatch(setLoading(true));

    api
      .get("/products")
      .then((response) => {
        dispatch(fetchProducts(response.data.payload));
      })
      .catch((error) => console.error("Error fetching products:", error));
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [dispatch, fetchData]);

  const handleDeleteProduct = async (product) => {
    api
      .delete(`/products/${product.original._id}`)
      .then((response) => {
        setLoading(false);
        fetchData();
        toast.success("Variant deleting successfully!");
      })
      .catch((error) => {
        console.error("Error deleting product:", error);
        setLoading(false);
        toast.error(
          error?.response.data.message
            ? error?.response.data.message
            : "Error deleting product."
        );
      });
  };

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      {/* Conditional rendering for loading indicator */}
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "16px",
            }}
          >
            <Typography variant="h6">Products</Typography>
            <Link to="/products/add" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<FiPlus />}
                sx={{ borderRadius: "20px" }}
              >
                Add Product
              </Button>
            </Link>
          </Box>
          <Table
            data={products}
            fields={productsColumns}
            numberOfRows={products.length}
            enableTopToolBar={true}
            enableBottomToolBar={true}
            enablePagination={true}
            enableRowSelection={true}
            enableColumnFilters={true}
            enableEditing={true}
            enableColumnDragging={true}
            showEdit={true}
            showPreview={true}
            routeLink="products"
            onDeleteRow={handleDeleteProduct}
          />
        </Box>
      )}
    </Box>
  );
};

export default Products;
