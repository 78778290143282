import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: [],
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    addCategory: (state, action) => {
      state.categories.push(action.payload);
    },
    updateCategory: (state, action) => {
      const updatedCategory = action.payload;
      const categoryIndex = state.categories.findIndex(
        (category) => category.id === updatedCategory.id
      );
      if (categoryIndex !== -1) {
        state.categories[categoryIndex] = updatedCategory;
      }
    },
  },
});

export const { setCategories, addCategory,updateCategory } = categorySlice.actions;
export default categorySlice.reducer;
