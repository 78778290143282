import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../api/api";
import { updateCategory } from "../store/categorySlice";

const UpdateCategory = ({ open, handleClose, category }) => {
  const [updatedCategory, setUpdatedCategory] = useState(
    category.original.name
  );
  const [categoryErrors, setCategoryErrors] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (category.original.name === updatedCategory) {
      setCategoryErrors("Make changes to update.");
      return;
    }
    if (!updatedCategory) {
      setCategoryErrors("Category is required.");
      return;
    }

    const updatedData = {
      name: updatedCategory,
    };

    api
      .put(`/categories/${category.original._id}`, updatedData)
      .then((response) => {
        console.log("Category updated successfully:", response.data);
        dispatch(updateCategory(response.data.payload));
        handleClose();
        toast.success("Category updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating category:", error);
        toast.error(error.response.data.message || "Error updating category.");
      });
  };

  return (
    <React.Fragment>
      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Edit Category</DialogTitle>
          <DialogContent>
            <DialogContentText>Update category name</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="categoryName"
              label="Category Name"
              type="text"
              fullWidth
              variant="standard"
              required
              value={updatedCategory}
              onChange={(e) => {
                setUpdatedCategory(e.target.value);
                setCategoryErrors("");
              }}
              error={!!categoryErrors}
              helperText={categoryErrors}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit} variant="contained">
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default UpdateCategory;
