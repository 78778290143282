import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../api/api";
import Table from "../components/Table";
import Stats from "../components/home/stats/Stats";
import { ordersColumns } from "../data/orders";
import { setOrderDetails } from "../store/orderSlice"; // Import the action

const Dashboard = () => {
  const [counts, setCounts] = useState({customers:0,orders:0,totalAmount:0});
  const orderDetails = useSelector((state) => state.order.orderDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    api
    .get("/dashboard/counts")
    .then((response) => {
      setCounts(response.data.payload);
    })
    .catch((error) => console.error("Error fetching customers:", error))
    api
      .get("/orders")
      .then((response) => {
        const fetchedOrderDetails = response.data.payload;
        dispatch(setOrderDetails(fetchedOrderDetails));
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
      });
  }, [dispatch]);

  const ComponentWrapper = styled(Box)({
    marginTop: "10px",
    paddingBottom: "10px",
  });

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <ComponentWrapper>
        <Stats counts={counts}/>
      </ComponentWrapper>
      <ComponentWrapper>
        <Typography variant="h5" sx={{ my: 3 }}>
          Latest Orders
        </Typography>
        {/* Replace 'orders' and 'ordersColumns' with 'orderDetails' and 'ordersDetailsColumns' */}
        <Table
          data={orderDetails}
          fields={ordersColumns}
          numberOfRows={5}
          enableTopToolBar={false}
          enableBottomToolBar={false}
          enablePagination={false}
          enableRowSelection={false}
          enableColumnFilters={false}
          enableEditing={false}
          enableColumnDragging={false}
        />
      </ComponentWrapper>
    </Box>
  );
};

export default Dashboard;
