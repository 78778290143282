import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  variants: [],
};

const variantSlice = createSlice({
  name: "variant",
  initialState,
  reducers: {
    setVariants: (state, action) => {
      state.variants = action.payload;
    },
    addVariant: (state, action) => {
      state.variants.push(action.payload);
    },
    updateVariant: (state, action) => {
      const updateVariant = action.payload;
      const variantIndex = state.variants.findIndex(
        (variant) => variant.id === updateVariant.id
      );
      if (variantIndex !== -1) {
        state.variants[variantIndex] = updateVariant;
      }
    },
  },
});

export const { setVariants, addVariant, updateVariant } = variantSlice.actions;
export default variantSlice.reducer;
