export const handleErrors = (error, setError) => {
  if (error.response) {
    const { status } = error.response;

    if (status === 401) {
      setError(
        "Unauthorized: You do not have permission to access this resource."
      );
    } else if (status === 400) {
      setError(
        "Bad Request: The request could not be understood or was missing required parameters."
      );
    } else if (status === 500) {
      setError(
        "Internal Server Error: Something went wrong on the server side."
      );
    } else {
      setError("An error occurred.");
    }
  } else if (error.request) {
    setError("No response received from the server.");
  } else {
    setError("An error occurred.");
  }
};
