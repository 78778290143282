import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../api/api";
import Table from "../components/Table";
import { ordersColumns } from "../data/orders";
import { setLoading, setOrderDetails } from "../store/orderSlice"; // Import the actions

const Orders = () => {
  const orderDetails = useSelector((state) => state.order.orderDetails); // Access orderDetails from the Redux store
  const loading = useSelector((state) => state.order.loading); // Access loading state from the Redux store
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoading(true)); // Set loading state to true before fetching data
    api
      .get("/orders")
      .then((response) => {
        dispatch(setOrderDetails(response.data.payload)); // Update orderDetails in the store
        dispatch(setLoading(false)); // Set loading state to false after fetching data
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
        dispatch(setLoading(false)); // Set loading state to false in case of an error
      });
  }, [dispatch]);

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      {/* Conditional rendering for loading state */}
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <React.Fragment>
          <Typography variant="h6" sx={{ marginBottom: "14px" }}>
            Orders
          </Typography>
          <Table
            data={orderDetails}
            fields={ordersColumns}
            numberOfRows={orderDetails.length}
            enableTopToolBar={true}
            enableBottomToolBar={true}
            enablePagination={true}
            enableRowSelection={true}
            enableColumnFilters={true}
            enableEditing={true}
            enableColumnDragging={true}
            showPreview
            routeLink="orders"
            disableDelete={true}
          />
        </React.Fragment>
      )}
    </Box>
  );
};

export default Orders;
