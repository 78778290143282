export function joinObjectPropertiesWithComma(obj, properties) {
  if (
    !obj ||
    typeof obj !== "object" ||
    !Array.isArray(properties) ||
    properties.length === 0
  ) {
    return "";
  }

  return properties.map((prop) => obj[prop]).join(", ");
}
export function joinListObjectPropertiesWithComma(arr, properties) {
  if (
    !Array.isArray(arr) ||
    arr.length === 0 ||
    !Array.isArray(properties) ||
    properties.length === 0
  ) {
    return "";
  }

  return arr
    .map((obj) => properties.map((prop) => obj[prop]).join(", "))
    .join(" | ");
}
