export const productsColumns = [
  {
    accessorKey: "name",
    header: "Product",
  },
  {
    accessorKey: "description",
    header: "Description",
  },
  {
    accessorKey: "images",
    header: "Image",
    Cell: ({ cell }) => (
      <div>
        {Array.isArray(cell.getValue()) && cell.getValue().length > 0 ? (
          <img src={cell.getValue()[0]} alt="" width={60} />
        ) : (
          <span>No Image</span>
        )}
      </div>
    ),
  },
  {
    accessorKey: "category.name", //access nested data with dot notation
    header: "Category",
    Cell: ({ cell }) => <span>{cell.getValue() || ""}</span>,
  },

  {
    accessorKey: "price",
    header: "Price",
    Cell: ({ cell }) => <span>${cell.getValue() || ""}</span>,
  },
  {
    accessorKey: "stock",
    header: "Stock",
  },
];
