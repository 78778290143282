import { Button, IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import MaterialReactTable from "material-react-table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FiEdit, FiEye, FiTrash } from "react-icons/fi";
import { Link } from "react-router-dom";

export const Table = ({
  data,
  fields,
  numberOfRows,
  enableTopToolBar,
  enableBottomToolBar,
  enablePagination,
  enableRowSelection,
  enableColumnFilters,
  enableEditing,
  enableColumnDragging,
  showPreview,
  showEdit,
  routeLink,
  isModalEdit,
  EditModalComponent,
  onDeleteRow,
  disableDelete = false,
}) => {
  const columns = useMemo(() => fields, []);

  const [tableData, setTableData] = useState(data);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const handleDeleteRow = useCallback(
    (row) => {
      if (!window.confirm("Are you sure you want to delete")) {
        return;
      }
      onDeleteRow(row);
    },
    [onDeleteRow]
  );

  const handleOpenEditModal = useCallback((row) => {
    setSelectedCategory(row);
  }, []);

  const handleCloseEditModal = useCallback(() => {
    setSelectedCategory(null);
  }, []);

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={tableData.slice(0, numberOfRows)}
        getRowId={(row) => row._id}
        enableEditing={enableEditing}
        enableColumnDragging={enableColumnDragging}
        enableColumnOrdering
        enableRowSelection={enableRowSelection}
        enableColumnFilters={enableColumnFilters}
        enablePagination={enablePagination}
        enableBottomToolbar={enableBottomToolBar}
        enableTopToolbar={enableTopToolBar}
        renderRowActions={({ row }) => (
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton
                color="error"
                onClick={() => handleDeleteRow(row)}
                disabled={disableDelete}
              >
                <FiTrash />
              </IconButton>
            </Tooltip>
            {showPreview && routeLink && (
              <Tooltip arrow placement="right" title="View">
                <Link to={`/${routeLink}/${row.id}`}>
                  <IconButton>
                    <FiEye />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
            {showEdit && routeLink && (
              <Tooltip arrow placement="right" title="Edit">
                <Link to={`/${routeLink}/edit/${row.id}`}>
                  <IconButton>
                    <FiEdit />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
            {routeLink && isModalEdit && (
              <Tooltip arrow placement="right" title="Edit">
                <IconButton onClick={() => handleOpenEditModal(row)}>
                  <FiEdit />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
        renderTopToolbarCustomActions={({ table }) => (
          <Button
            disableElevation
            disabled={disableDelete}
            color="error"
            // disabled={!table.getIsSomeRowsSelected()}
            variant="contained"
            // onClick={handleDelete}
          >
            Delete Selected
          </Button>
        )}
        muiTableBodyRowProps={{ hover: false }}
        muiTablePaperProps={{
          sx: {
            padding: "20px",
            borderRadius: "15px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
          },
        }}
        muiTableContainerProps={{
          sx: { borderRadius: "15px" },
        }}
        muiTableHeadCellProps={{
          sx: {
            fontSize: "14px",
            fontWeight: "bold",
          },
        }}
        muiTableHeadProps={{
          sx: {
            "& tr th": {
              borderWidth: "1px",
              borderColor: "divider",
              borderStyle: "solid",
            },
          },
        }}
        muiTableBodyProps={{
          sx: {
            "& tr td": {
              borderWidth: "1px",
              borderColor: "divider",
              borderStyle: "solid",
            },
          },
        }}
      />

      {/* EditCategory modal */}
      {selectedCategory && (
        <EditModalComponent
          open={true}
          handleClose={handleCloseEditModal}
          category={selectedCategory}
          variant={selectedCategory}
        />
      )}
    </>
  );
};

export default Table;
