// orderSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderDetails: [],
  loading: true,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrderDetails: (state, action) => {
      state.orderDetails = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setOrderDetails, setLoading } = orderSlice.actions;
export default orderSlice.reducer;
