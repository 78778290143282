import { Box, Chip, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const SingleProduct = () => {
  const { id } = useParams();
  const products = useSelector((state) => state.product.products);

  const product = products.find((product) => {
    return product._id === id;
  });

  const {
    category: { name: categoryName },
    images,
    price,
    name,
    description,
    variants,
  } = product;

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Typography variant="h4">Product Details</Typography>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <img
              src={images[0]}
              alt={name}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <Typography variant="h4">{name}</Typography>
            <Typography variant="h5">${price}</Typography>

            <Typography variant="subtitle2">{description}</Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 4, my: 2 }}>
              <Typography variant="subtitle2">Category</Typography>
              <Chip label={categoryName} />
            </Box>
            {variants?.map((variant) => (
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 4, my: 2 }}
              >
                <Typography variant="subtitle2">
                  {variant?.variantId?.name}
                </Typography>
                <Chip label={`$${variant?.price}`} color={"success"} />
              </Box>
            ))}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default SingleProduct;
