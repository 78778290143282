import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../api/api";
import AddCategory from "../components/AddCategory";
import EditCategory from "../components/EditCategory";
import Table from "../components/Table";
import { categoriesColumns } from "../data/categories";
import { setCategories } from "../store/categorySlice";

const CategoryManagement = () => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);
  const categories = useSelector((state) => state.category.categories);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const toggleModal = () => {
    setOpen(!open);
  };

  const handleEditRow = (row) => {
    setSelectedRow(row);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedRow(null);
  };
  const handleDeleteCategory = async (category) => {
    api
      .delete(`/categories/${category.original._id}`)
      .then((response) => {
        setLoading(false);
        const newCategories = categories.filter(
          (item) => item.id !== category.id
        );
        dispatch(setCategories(newCategories));
        toast.success("Category deleting successfully!");
      })
      .catch((error) => {
        console.error("Error deleting category:", error);
        setLoading(false);
        toast.error(
          error?.response.data.message
            ? error?.response.data.message
            : "Error creating category."
        );
      });
  };
  useEffect(() => {
    api
      .get("/categories")
      .then((response) => {
        dispatch(setCategories(response.data.payload));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
        setLoading(false);
      });
  }, [dispatch]);

  return (
    <React.Fragment>
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">Categories</Typography>

          <Button
            variant="contained"
            color="primary"
            startIcon={<FiPlus />}
            sx={{ borderRadius: "20px" }}
            onClick={toggleModal}
          >
            Add Category
          </Button>
        </Box>

        <AddCategory open={open} toggleModal={toggleModal} />

        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Table
            data={categories}
            fields={categoriesColumns}
            numberOfRows={categories.length}
            enableTopToolBar={true}
            enableBottomToolBar={true}
            enablePagination={true}
            enableRowSelection={true}
            enableColumnFilters={true}
            enableEditing={true}
            enableColumnDragging={true}
            routeLink="categories"
            isModalEdit={true}
            EditModalComponent={EditCategory}
            handleEditRow={handleEditRow}
            onDeleteRow={handleDeleteCategory}
          />
        )}
        {selectedRow && (
          <EditCategory
            open={openEditModal}
            handleClose={handleCloseEditModal}
            category={selectedRow}
          />
        )}
      </Box>
    </React.Fragment>
  );
};

export default CategoryManagement;
