// store.js

import { configureStore } from "@reduxjs/toolkit";
import categoryReducer from "./categorySlice";
import customerReducer from "./customerSlice";
import imageUploadReducer from "./imageUploadSlice";
import orderReducer from "./orderSlice";
import productReducer from "./productSlice";
import variantReducer from "./variantSlice";

const store = configureStore({
  reducer: {
    order: orderReducer,
    customer: customerReducer,
    product: productReducer,
    imageUpload: imageUploadReducer,
    category: categoryReducer,
    variant: variantReducer,
  },
});

export default store;
