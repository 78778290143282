// api.js
import axios from "axios";
const baseURL = process.env.REACT_APP_API_BASE_URL;

// Create an instance of Axios with the base URL
const api = axios.create({
  baseURL,
});

// Export the API instance for use in other files
export default api;
